<template >
  <v-sheet
      v-if="displayVideo"
      :class="[{'mobile-fullscreen' : mobileFullscreen && !fullScreen}]"
      style="position: relative; "
      :style="`height : ${fullScreen ? '100%' : !SM ? (height+'px') : mobileFullscreen ? '100%' : '200px'}`"
      oncontextmenu="return false;"
      dark
  >
    <v-sheet
        v-if="displayControls && mobileFullscreen"
        style="position: absolute; right: 15px; top: 15px; z-index : 999; border-radius: 50%"
        color="#222222dd"
    >
      <v-btn
          @click.stop="playButton(); mobileFullscreen = false; trigger++ "
          icon>
        <v-icon >mdi-close</v-icon>
      </v-btn>
    </v-sheet>
    <div class="fill-height"  oncontextmenu="return false;">
      <div
          ref="player"
          @mouseleave="exitControls"
          @mouseover="showControls"
          @mousemove="showControls"
          @click="showControlsMobile"
          oncontextmenu="return false;"
          style="position : relative ; height: 100%; background-color: #181818"
      >

        <!-- Waiting Progress-->
        <!--        <v-sheet-->
        <!--            v-if="waiting || LOADING"-->
        <!--            color="transparent"-->
        <!--            class="d-flex align-center justify-center"-->
        <!--            style="position: absolute;top : 0; bottom: 0; left: 0; right: 0; z-index: 10"-->
        <!--            oncontextmenu="return false;"-->
        <!--        >-->
        <!--          <v-progress-circular indeterminate size="72" />-->
        <!--        </v-sheet>-->
        <!-- Play Button -->
        <v-sheet
            v-if="(!isPlaying && !LOADING && !isCollapsed) || (SM && displayControls && !isCollapsed)"
            @click="!LOADING && !SM ? playButton() : null"
            color="transparent"
            class="d-flex align-center justify-center"
            style="position: absolute;top : 0; bottom: 0px; left: 0; right: 0; z-index: 10"
            oncontextmenu="return false;"
        >
          <v-btn :style="`background-color : ${wsWARNING}`"  icon outlined @click.stop="!LOADING ? playButton() : null " x-large>
            <v-icon x-large>
              {{ isPlaying ? 'mdi-pause' : 'mdi-play' }}
            </v-icon>
          </v-btn>
        </v-sheet>
        <!-- Mobile Doubleclick Forward -->
        <v-sheet
            @click.stop="!SM ? playButton() : showControlsMobile()"
            @dblclick="SM ? seekForward() : null"
            oncontextmenu="return false;"
            color="transparent"
            style="position: absolute;top : 0; bottom: 50px; left: 50%; right: 0"
        />
        <!-- Mobile Doubleclick Backward -->
        <v-sheet
            @click.stop="!SM ? playButton() : showControlsMobile()"
            @dblclick="SM ? seekBackward() : null"
            oncontextmenu="return false;"
            color="transparent"
            style="position: absolute;top : 0; bottom: 50px; right: 50%; left: 0"
        />
        <!-- Video Element -->
        <video
            oncontextmenu="return false;"
            @timeupdate="timeupdate"
            @progress="progress"
            @pause="isPlaying = false"
            @play="isPlaying = true"
            @waiting="waiting = true"
            @stalled="waiting = true"
            @playing="waiting = false"
            @seeking="waiting = true"
            @seeked="waiting = false"
            @loadedmetadata="duration = video.duration"
            @keydown="keyboardControls"
            :poster="poster"
            ref="video"
            height="100%"
            width="100%"
            preload="auto"
            controlslist="nodownload"
            :playsinline="!disableInline"
            crossorigin="anonymous"
        >
          <source
              :src="url"
              type="video/mp4"
          >
          <track
              v-if="!!selectedSubtitles"
              :src="selectedSubtitles"
              srclang="en"
              label="English"
              kind="subtitles"
              default
          >
        </video>
        <!-- Video Controls -->
        <v-fade-transition>
          <v-sheet
              @click.stop=""
              v-if="(displayControls && !isMounted && !SM) || (SM && displayControls && mobileFullscreen)"
              @mouseover="mouseOnControls = true"
              @mouseout="mouseOnControls = false"
              color="#333333cc"
              class="px-2 py-2 wsRoundedHalf prevent-select"
              :style="`position: absolute; bottom: ${!SM ? 25 : 15}px;  left: ${!SM ? 25 : 15}px ; right:${!SM ? 25 : 15}px;z-index : 100`"
          >


            <!-- COLLAPSED-->
            <div v-if="isCollapsed"  class="d-flex align-center justify-space-between" >
              <v-btn @click.stop="playButton" small  class="mr-1"  icon>
                <v-icon >{{ isPlaying ? 'mdi-pause' : 'mdi-play' }}</v-icon>
              </v-btn>

              <v-btn  @click.stop="toggleCollapse" small  icon>
                <v-icon  :style="isCollapsed ? `transform: rotate(-90deg)` : null" >
                  {{ isCollapsed ? 'mdi-open-in-new' : 'mdi-picture-in-picture-bottom-right' }}
                </v-icon>
              </v-btn>
            </div>
            <!-- MOBILE CONTROLS-->
            <div v-else-if="SM"  class="d-flex align-center justify-space-between">
              <!-- Volume -->
              <div>
                <v-slider
                    :value="volume"
                    @change="changeVolume"
                    min="0"
                    max="100"
                    style="width: 60px"
                    class="mr-3"
                    hide-details
                    dense
                />
              </div>
              <!-- Play button , Current Time -->
              <div  class="d-flex align-center justify-center ">
                <!-- Seek Backward -->
                <v-btn @click.stop="seekBackward"  small  class="mr-1" icon>
                  <v-icon :small="isCollapsed" >mdi-rewind</v-icon>
                </v-btn>
                <!-- Play Button-->
                <v-btn large @click.stop="playButton" small  class="mr-1"  icon>
                  <v-icon large >{{ isPlaying ? 'mdi-pause' : 'mdi-play' }}</v-icon>
                </v-btn>
                <!-- Seek Forward -->
                <v-btn @click.stop="seekForward" small  class="mr-3"  icon>
                  <v-icon :small="isCollapsed">mdi-fast-forward</v-icon>
                </v-btn>
              </div>
              <!-- Settings Controls -->
              <div class="d-flex py-2">
                <v-menu
                    v-if="!isCollapsed || fullscreenToggled"
                    v-model="showMobileSettings"
                    close-on-click close-on-content-click
                    z-index="99999"
                    offset-y
                    top
                >
                  <template v-slot:activator="{attrs,on}">
                    <v-btn   v-bind="attrs" v-on="on"  small  icon>
                      <v-icon>
                        {{ "mdi-cog" }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-sheet width="150" dark  class="pa-1">
                    <!-- Collapse -->
                    <v-btn height="36" block @click.stop="toggleCollapse();showMobileSettings = false"  v-if="fullScreen" text class="noCaps justify-start">
                      <v-icon  :style="isCollapsed ? `transform: rotate(-90deg)` : null"  class="mr-2">
                        {{ isCollapsed ? 'mdi-open-in-new' : 'mdi-picture-in-picture-bottom-right' }}
                      </v-icon>
                      {{ $t('CollapseVideo') }}
                    </v-btn>
                    <!-- Speed -->
                    <v-btn @click.stop="openContext($event,'speed');showMobileSettings = false" block text class="noCaps justify-start">
                      <v-icon class="mr-2" >mdi-play-speed</v-icon>
                      {{ $t('Speed') }}
                    </v-btn>
                    <ft-select
                        v-model="playbackRate"
                        :expanded="displaySpeedMenu"
                        absolute :x="x" :y="y"
                        @input="changeSpeed($event)"
                        :items="speedSelect"
                        @expand="menuExpanded = $event; displaySpeedMenu = $event "
                        dark
                        text-color="#ffffff"
                    />
                    <!-- Subtitles -->
                    <v-btn
                        v-if="subtitles.length > 0"
                        @click.stop="openContext($event,'subtitles');showMobileSettings = false"
                        class="noCaps justify-start"
                        text block
                    >
                      <v-icon class="mr-2" >mdi-closed-caption</v-icon>
                      {{ $t('Subtitles') }}
                    </v-btn>
                    <ft-select
                        v-if="subtitles.length > 0"
                        v-model="selectedSubtitles"
                        @input="changeSubtitle($event)"
                        @expand="menuExpanded = $event; displaySubtitlesMenu = $event "
                        :items="subtitlesSelect"
                        :expanded="displaySubtitlesMenu"
                        :x="subtitles_x"
                        :y="subtitles_y"
                        text-color="#ffffff"
                        absolute
                        dark
                    />
                    <!-- Qualities -->
                    <v-btn
                        v-if="qualitiesSelect.length > 0"
                        @click.stop="openContext($event,'qualities');showMobileSettings = false"
                        text block
                        class="noCaps justify-start"
                    >
                      <v-icon class="mr-2" >mdi-quality-high</v-icon>
                      {{ $t('Quality') }}
                    </v-btn>
                    <ft-select
                        :value="url"
                        :expanded="displayQualitiesMenu"
                        absolute :x="qualities_x" :y="qualities_y"
                        v-if="qualitiesSelect.length > 0"
                        @input="changeQuality($event)"
                        :items="qualitiesSelect"
                        @expand="menuExpanded = $event ; displayQualitiesMenu = $event"
                        dark
                        text-color="#ffffff"
                    />

                  </v-sheet>
                </v-menu>
                <v-btn v-if="fullScreen"  @click.stop="toggleCollapse" small  icon>
                  <v-icon  :style="isCollapsed ? `transform: rotate(-90deg)` : null" >
                    {{ isCollapsed ? 'mdi-open-in-new' : 'mdi-picture-in-picture-bottom-right' }}
                  </v-icon>
                </v-btn>
                <!-- Expand -->
                <v-btn @click.stop="toggleFullscreen" :class="!isCollapsed ? 'ml-1' : null"  small   icon>
                  <v-icon :size="!isCollapsed ? 34 : null"> {{ fullscreenToggled ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}</v-icon>
                </v-btn>
              </div>
            </div>
            <!-- FULL CONTROLS-->
            <div v-else class="d-flex align-center justify-space-between" >
              <!-- Play button , Current Time -->
              <div class="d-flex align-center mr-3 ">
                <!-- Play Button-->
                <v-btn @click.stop="playButton" small  class="mr-1"  icon>
                  <v-icon >{{ isPlaying ? 'mdi-pause' : 'mdi-play' }}</v-icon>
                </v-btn>

                <!-- Volume  -->
                <v-btn @click.stop="toggleMute" small  class="mr-1"  icon>
                  <v-icon >{{ soundIcon }}</v-icon>
                </v-btn>
                <v-slider
                    :value="volume"
                    @change="changeVolume"
                    min="0"
                    max="100"
                    style="width: 75px"
                    class="mr-3"
                    hide-details
                    dense
                />
                <!-- Seek Backward -->
                <v-btn @click.stop="seekBackward"  small  class="mr-1" icon>
                  <v-icon :small="isCollapsed" >mdi-rewind</v-icon>
                </v-btn>
                <!-- Seek Forward -->
                <v-btn @click.stop="seekForward" small  class="mr-3"  icon>
                  <v-icon :small="isCollapsed">mdi-fast-forward</v-icon>
                </v-btn>
                <!-- Current Time -->
                <h4 v-if="!SM" class="text-no-wrap" style="cursor: default;">
                  {{ timeFormatted }} / {{ videoDuration }}
                </h4>
              </div>
              <!-- Settings Buttons -->
              <div class="d-flex align-center">
                <!-- Collapse -->
                <v-btn v-if="fullScreen"  @click.stop="toggleCollapse" small  icon>
                  <v-icon  :style="isCollapsed ? `transform: rotate(-90deg)` : null" >
                    {{ isCollapsed ? 'mdi-open-in-new' : 'mdi-picture-in-picture-bottom-right' }}
                  </v-icon>
                </v-btn>
                <!-- Speed -->
                <v-btn @click.stop="openContext($event,'speed')" small icon>
                  <v-icon >mdi-play-speed</v-icon>
                </v-btn>
                <ft-select
                    v-model="playbackRate"
                    :expanded="displaySpeedMenu"
                    absolute :x="x" :y="y"
                    @input="changeSpeed($event)"
                    :items="speedSelect"
                    @expand="menuExpanded = $event; displaySpeedMenu = $event "
                    dark
                    text-color="#ffffff"
                />

                <!-- Subtitles -->
                <v-btn
                    v-if="subtitles.length > 0"
                    @click.stop="openContext($event,'subtitles')"
                    small icon
                >
                  <v-icon >mdi-closed-caption</v-icon>
                </v-btn>
                <ft-select
                    v-if="subtitles.length > 0"
                    v-model="selectedSubtitles"
                    @input="changeSubtitle($event)"
                    @expand="menuExpanded = $event; displaySubtitlesMenu = $event "
                    :items="subtitlesSelect"
                    :expanded="displaySubtitlesMenu"
                    :x="subtitles_x"
                    :y="subtitles_y"
                    text-color="#ffffff"
                    absolute
                    dark
                />
                <!-- Qualities -->
                <v-btn
                    v-if="qualitiesSelect.length > 0"
                    @click.stop="openContext($event,'qualities')"
                    small icon
                >
                  <v-icon >mdi-quality-high</v-icon>
                </v-btn>
                <ft-select
                    :value="url"
                    :expanded="displayQualitiesMenu"
                    absolute :x="qualities_x" :y="qualities_y"
                    v-if="qualitiesSelect.length > 0"
                    @input="changeQuality($event)"
                    :items="qualitiesSelect"
                    @expand="menuExpanded = $event ; displayQualitiesMenu = $event"
                    dark
                    text-color="#ffffff"
                />
                <!-- Expand -->
                <v-btn @click.stop="toggleFullscreen"  small   icon>
                  <v-icon>  mdi-fullscreen</v-icon>
                </v-btn>
              </div>
            </div>





            <!-- Progress Control -->



            <v-sheet @click.stop="" class="ml-2 mr-1 mb-2 mt-2" style="border-radius: 5px; position: relative" height="15" color="#555555">
              <v-sheet  style="opacity: 0; position: absolute; top: 0;bottom: 0;left: -8px;right: -8px;z-index: 2; "  height="4" color="transparent">
                <v-slider
                    ref="progress_slider"
                    @mousedown="seekingOn = true"
                    @mouseup="seekingOn = false"
                    class="mt-n2"
                    v-model="currentTime"
                    @change="seek"
                    min="0"
                    :max="duration"
                    hide-details
                    dense
                />
              </v-sheet>

              <v-progress-linear
                  :value="currentTimePercent"
                  :color="wsWARNING"
                  :buffer-value="bufferedTime"
                  class="pointer"
                  height="15"
                  rounded
              />

            </v-sheet>
            <!-- TIME FOR SMALL -->
            <h6 v-if="SM" class="text-no-wrap text-center" style="cursor: default;">
              {{ timeFormatted }} / {{ videoDuration }}
            </h6>

          </v-sheet>
        </v-fade-transition>

      </div>
    </div>
  </v-sheet>

  <v-sheet
      :style="posterStyle"
      class="d-flex align-center justify-center"
      height="100%" :min-height="`${fullScreen ? '100%' : !SM ? (height+'px') : mobileFullscreen ? '100%' : '200px'}`" dark v-else
      style="background-color : #030303 !Important"
      color="black"
  >
    <v-btn @click="initVideo(true)" icon outlined   x-large :style="`background-color: ${wsWARNING}`">
      <v-icon x-large>
        {{ isPlaying ? 'mdi-pause' : 'mdi-play' }}
      </v-icon>
    </v-btn>

  </v-sheet>
</template>

<script>

import {mapActions} from "vuex";
import languagesList from "@/mixins/languagesList";

export default {
  name: "wsVideo",
  components: {},
  props: {
    file: {
      type: Object,
      default() {
        return {}
      }
    },
    src: {
      type: String,
      default: ''
    },
    refresh: {
      type: Number,
      default: 0,
    },
    courseId: {
      type: String
    },
    student: {
      type: Boolean
    },
    testId: {
      type: String,
    },
    fullScreen: {
      type: Boolean,
      default: false
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 350
    },
    isCollapsed: {
      type: Boolean,
      default: false
    },
    externalHover: {
      type: Boolean,
      default: false
    },
    elementId: {
      type: Number,
      default: null
    }
  },
  mixins: [languagesList],
  data() {
    return {
      displayVideo: false,
      timeOut: null,
      progressSlider: null,

      mobileFullscreen: false,
      disableInline: false,
      desktopFullscreen: false,
      video: null,
      duration: null,
      waiting: false,
      playbackRate: 1,
      volume: 100,
      lastVolume: 100,
      isMounted: true,
      trigger: 0,

      showMobileSettings: false,

      seekingOn: false,

      x: 0,
      y: 0,
      displaySpeedMenu: false,

      subtitles_x: 0,
      subtitles_y: 0,
      displaySubtitlesMenu: false,

      qualities_x: 0,
      qualities_y: 0,
      displayQualitiesMenu: false,

      uuid: '',
      Player: null,
      url: null,
      poster: null,
      subtitles: [],
      qualities: [],
      currentTime: 0,
      bufferedTime: 0,
      displayControls: true,
      mouseOnControls: false,
      menuExpanded: false,

      selectedSubtitles: 'null',

      isPlaying: false,
      fullscreenToggled: false,
    }
  },
  watch: {
    file() {
      this.initVideo()
    },
    displayControls(value) {
      this.$emit('controlsDisplay', value)
      this.closeSettingsDialogs()
    },
    externalHover(value) {
      if (value) {
        this.displayControls = true
      }
    },
    mobileFullscreen() {
      this.trigger++
    }
  },
  computed: {
    posterStyle() {
      let style = '';
      if (this.file.poster) {
        style += `background : url('${this.file.poster}');`
        style += `background-size : cover;`
        style += `background-position : center center;`
      } else {
        style += 'background-color : #040404; '
      }

      return style
    },
    qualitiesSelect() {
      let items = []
      if (Object.keys(this.qualities).length === 0) {
        return []
      }
      Object.keys(this.qualities).forEach(key => {
        let text = key === '9999' ? this.$t('Original') : key
        items.push({text: text, value: this.qualities[key]})
      })
      return items
    },
    subtitlesSelect() {
      let items = []
      if (this.subtitles.length === 0) {
        return []
      }
      this.subtitles.forEach(item => {
        items.push({text: this.LANGUAGE_TEXT(item.lang), value: item.url})
      })
      items.unshift({text: this.$t('Disabled'), value: 'null'})
      return items
    },
    speedSelect() {
      return [
        {text: '0,5x', value: 0.5},
        {text: '0,75x', value: 0.75},
        {text: '1.0x', value: 1.0},
        {text: '1,25x', value: 1.25},
        {text: '1,5x', value: 1.5},
        {text: '1,75x', value: 1.75},
        {text: '2.0x', value: 2.0},
      ]
    },
    videoDuration() {

      if (!this.duration) {
        return '00 : 00'
      }

      let hours = Math.floor(this.duration / 60 / 60).toFixed()
      if (hours > 0) {
        hours = hours < 10 ? `0${hours}` : hours
        hours = hours + " : "
      } else {
        hours = ''
      }
      let minutes = Math.floor(this.duration / 60 % 60).toFixed()
      minutes = minutes < 10 ? `0${minutes}` : minutes
      let seconds = Math.floor(this.duration % 60)
      seconds = seconds < 10 ? `0${seconds}` : seconds

      return hours + `${minutes} : ${seconds}`

    },
    soundIcon() {

      if (this.volume > 70) {
        return 'mdi-volume-high'
      }
      if (this.volume > 40) {
        return 'mdi-volume-medium'
      }
      if (this.volume > 1) {
        return 'mdi-volume-low'
      }
      return 'mdi-volume-off'
    },
    currentTimePercent() {
      if (!this.duration) {
        return 0
      }
      return (this.currentTime / this.duration) * 100
    },
    timeFormatted() {

      if (!this.currentTime) {
        return '00 : 00'
      }

      let hours = Math.floor(this.currentTime / 60 / 60).toFixed()
      if (hours > 0) {
        hours = hours < 10 ? `0${hours}` : hours
        hours = hours + " : "
      } else {
        hours = ''
      }
      let minutes = Math.floor(this.currentTime / 60 % 60).toFixed()
      minutes = minutes < 10 ? `0${minutes}` : minutes
      let seconds = Math.floor(this.currentTime % 60)
      seconds = seconds < 10 ? `0${seconds}` : seconds

      return hours + `${minutes} : ${seconds}`

    },
  },
  methods: {
    ...mapActions('upload', ['GET_PAGE_ELEMENT_FILE', 'GET_PRIVATE_FILE', 'GET_PUBLIC_FILE', 'GET_STUDENT_COMPONENT_FILE', 'GET_STUDENT_TEST_FILE']),
    closeSettingsDialogs() {
      this.displaySpeedMenu = false
      this.displaySubtitlesMenu = false
      this.displayQualitiesMenu = false
      this.showMobileSettings = false
    },
    //video events
    progress() {
      this.waiting = false
      this.getBufferedTime()
    },
    timeupdate() {
      this.getCurrentTime()
    },
    seek($event) {
      this.video.currentTime = $event
    },
    // controls
    playButton() {
      if (this.LOADING) {
        return
      }
      if (this.SM && this.displayControls && this.video.paused) {
        this.displayControls = false
      }
      if (this.SM && this.video.pause && !this.mobileFullscreen) {
        this.mobileFullscreen = true
      }
      this.video.paused ? this.video.play() : this.video.pause()
    },
    changeSubtitle($event) {
      this.selectedSubtitles = $event
      var track = this.video.textTracks[0];
      track.mode = 'showing';
    },
    changeQuality(url) {

      let time = this.video.currentTime
      this.video.pause()
      this.url = url
      this.video.load();
      this.video.currentTime = time;
      this.video.play()
    },
    seekForward() {
      if (!this.video) {
        return
      }
      if (!this.video.currentTime) {
        return
      }
      this.video.currentTime += 15;
    },
    seekBackward() {
      if (!this.video) {
        return
      }
      if (!this.video.currentTime) {
        return
      }
      this.video.currentTime -= 15;
    },
    toggleCollapse() {
      this.$emit('toggleCompact')
      if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement) {
        let docElm = document;
        if (docElm.exitFullscreen) {
          docElm.exitFullscreen();
        } else if (docElm.webkitExitFullscreen) {
          docElm.webkitExitFullscreen();
        } else if (docElm.mozCancelFullScreen) {
          docElm.mozCancelFullScreen();
        } else if (docElm.msExitFullscreen) {
          docElm.msExitFullscreen();
        }
      }
    },
    toggleFullscreen() {
      let wrapper = this.$refs.player
      if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement) {
        let docElm = document;
        if (docElm.exitFullscreen) {
          this.desktopFullscreen = false
          this.fullscreenToggled = false
          docElm.exitFullscreen();
        } else if (docElm.webkitExitFullscreen) {
          this.desktopFullscreen = false
          this.fullscreenToggled = false
          docElm.webkitExitFullscreen();
        } else if (docElm.mozCancelFullScreen) {
          this.desktopFullscreen = false
          this.fullscreenToggled = false
          docElm.mozCancelFullScreen();
        } else if (docElm.msExitFullscreen) {
          this.desktopFullscreen = false
          this.fullscreenToggled = false
          docElm.msExitFullscreen();
        }
      } else {
        this.isCollapsed = false
        if (wrapper.requestFullScreen) {
          this.desktopFullscreen = true
          if (this.DEVICE !== 'mobile') {
            this.fullscreenToggled = true
          }
          wrapper.requestFullScreen();
        } else if (wrapper.webkitRequestFullScreen) {
          this.desktopFullscreen = true
          if (this.DEVICE !== 'mobile') {
            this.fullscreenToggled = true
          }
          wrapper.webkitRequestFullScreen();
        } else if (wrapper.mozRequestFullScreen) {
          this.desktopFullscreen = true
          if (this.DEVICE !== 'mobile') {
            this.fullscreenToggled = true
          }
          wrapper.mozRequestFullScreen();
        } else if (wrapper.msRequestFullScreen) {
          this.desktopFullscreen = true
          if (this.DEVICE !== 'mobile') {
            this.fullscreenToggled = true
          }
          wrapper.msRequestFullScreen();
        } else if (this.video.enterFullScreen) {
          if (this.DEVICE !== 'mobile') {
            this.fullscreenToggled = true
          }
          this.video.enterFullScreen()
        } else if (this.video.webkitEnterFullScreen) {
          if (this.DEVICE !== 'mobile') {
            this.fullscreenToggled = true
          }
          this.video.webkitEnterFullScreen()
        } else if (this.video.mozEnterFullScreen) {
          if (this.DEVICE !== 'mobile') {
            this.fullscreenToggled = true
          }
          this.video.mozEnterFullScreen()
        } else if (this.video.msEnterFullScreen) {
          if (this.DEVICE !== 'mobile') {
            this.fullscreenToggled = true
          }
          this.video.msEnterFullScreen()
        }

      }
    },
    changeSpeed(value) {
      if (!this.video) {
        return
      }
      this.video.playbackRate = value
    },
    changeVolume(value) {
      this.volume = value
      if (this.value === 0) {
        this.toggleMute()
      }
      if (!this.video) {
        return
      }
      this.video.volume = this.volume / 100
    },
    toggleMute() {
      if (!this.video) {
        return
      }
      if (!this.video.muted) {
        this.lastVolume = this.volume
        this.volume = 0
        this.video.muted = true
      } else {
        this.volume = this.lastVolume
        this.video.muted = false
        this.video.volume = this.volume / 100


      }

    },
    // technical
    openContext($event, type) {
      $event.preventDefault()

      if (type === 'subtitles') {
        this.displaySubtitlesMenu = false
        this.subtitles_x = $event.clientX
        this.subtitles_y = $event.clientY - 30
        this.$nextTick(() => {
          this.displaySubtitlesMenu = true
        })
      }
      if (type === 'qualities') {
        this.displayQualitiesMenu = false
        this.qualities_x = $event.clientX
        this.qualities_y = $event.clientY - 30
        this.$nextTick(() => {
          this.displayQualitiesMenu = true
        })
      }
      if (type === 'speed') {
        this.displaySpeedMenu = false
        this.x = $event.clientX
        this.y = $event.clientY - 30
        this.$nextTick(() => {
          this.displaySpeedMenu = true
        })
      }
    },
    exitControls() {
      if (this.SM) {
        return
      }
      return !this.menuExpanded && !this.externalHover ? this.displayControls = false : null
    },
    showControls() {

      if (this.SM) {
        return
      }
      this.isMounted = false

      if (this.timeOut) {
        clearTimeout(this.timeOut)
      }
      this.displayControls = true
      this.timeOut = setTimeout(() => {
        if (!this.mouseOnControls && !this.menuExpanded && !this.externalHover) {
          this.displayControls = false
        }
      }, 1000)

    },
    showControlsMobile() {
      if (this.SM) {
        this.displayControls = !this.displayControls
      }
    },
    getCurrentTime() {
      if (this.seekingOn) {
        return
      }
      if (!this.video) {
        return
      }
      if (!this.video.duration) {
        return
      }
      if (!this.video.currentTime) {
        return
      }

      this.currentTime = this.video.currentTime

    },
    getBufferedTime() {
      if (!this.video) {
        return 0
      }
      if (!this.video.buffered) {
        return 0
      }
      if (this.video.buffered.length === 0) {
        return 0
      }

      this.bufferedTime = (this.video.buffered.end(this.video.buffered.length - 1) / this.video.duration) * 100
    },
    keyboardControls($event) {

      if (this.desktopFullscreen || this.fullScreen) {
        if (($event.code === 'Space' || $event.key === ' ') && !this.LOADING) {
          this.playButton()
        }
        if (($event.code === 'ArrowLeft' || $event.key === 'ArrowLeft') && !this.LOADING) {
          this.seekBackward()
        }
        if (($event.code === 'ArrowRight' || $event.key === 'ArrowRight') && !this.LOADING) {
          this.seekForward()
        }
      }


    },


    // AJAX
    async getVideoFile(play = false) {

      if (this.student) {
        return this.openStudentVideo(this.file, play)
      }

      if (!this.file.mime.includes('video')) {
        this.notify(this.$t('WrongVideoFileFormat'))
      }

      let result = false
      if (this.elementId) {
        result = await this.GET_PAGE_ELEMENT_FILE({file: this.file.uuid, element: this.elementId})
      } else {
        result = this.isPublic ? await this.GET_PUBLIC_FILE(this.file.uuid) : await this.GET_PRIVATE_FILE({
          uuid : this.file.uuid, stream : true
        })
      }

      if (!result) {
        return this.notify(this.$t('FileNotfound'))
      }

      if (typeof result.url === 'object') {
        this.qualities = result.url
        this.url = result.url[Object.keys(result.url)[0]]
      } else {
        this.url = result.url
      }
      this.poster = this.file.poster
      this.subtitles = result.subtitles
      this.videoSource = result.url

      this.$emit('uploaded')
      this.video.load()
      if (play) {
        this.playButton()
      }
    },

    async openStudentVideo(file, play = false) {

      let data = null
      let result = null;
      if (this.testId) {
        data = {
          file: file.uuid,
          test: this.testId,
          stream : true
        }
        result = await this.GET_STUDENT_TEST_FILE(data)
      } else {
        data = {
          file: file.uuid,
          course: this.courseId,
          stream : true
        }
        result = await this.GET_STUDENT_COMPONENT_FILE(data)
      }

      if (!result) {
        return this.notify(this.$t('FileNotfound'))
      }

      if (typeof result.url === 'object') {
        this.qualities = result.url
        this.url = result.url[Object.keys(result.url)[0]]
      } else {
        this.url = result.url
      }
      this.subtitles = result.subtitles
      this.poster = this.file.poster

      this.$emit('uploaded')
      this.video.load()
      if (play) {
        this.playButton()
      }
    },

    initVideo(play = false) {
      this.displayVideo = true
      this.uuid = this.$uuid.v4()
      if (this.file.uuid) {
        this.getVideoFile(play)
      }
      setTimeout(() => {
        this.video = this.$refs.video
        this.progressSlider = this.$refs.progress_slider.$el
        this.progressSlider.addEventListener('touchstart', this.sliderTouchOnFunction)
        this.progressSlider.addEventListener('touchend', this.sliderTouchOffFunction)
      }, 250)
    },


    sliderTouchOnFunction() {
      this.seekingOn = true
    },
    sliderTouchOffFunction() {
      this.seekingOn = false
    }

  },
  mounted() {
    // this.initVideo()
    window.addEventListener('keydown', this.keyboardControls);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyboardControls);
  }
}
</script>

<style scoped>
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.mobile-fullscreen {
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 999 !important;
}

</style>